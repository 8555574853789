<template>
	<div >
		<div class="ft20 cl-black cl-main ftw500">余额记录</div>

		<div class="mt20">
			<div class="form-search-box ">
				<a-form layout="inline">
					<!-- <a-form-item label="会员昵称">
						<a-input v-model="search.nick_name" placeholder="请输入会员昵称"></a-input>
					</a-form-item> -->

					<a-form-item label="会员手机号">
						<a-input v-model="search.mobile" placeholder="请输入会员手机号"></a-input>
					</a-form-item>

					<a-form-item label="操作日期">
						<a-range-picker @change="changeOperateTime" v-model="search.operate_time" valueFormat="YYYY-MM-DD" />
					</a-form-item>
					<a-form-item>
						<a-button @click="searchAct" type="primary">查询</a-button>
						<a-button @click="cancelAct" class="ml10">取消</a-button>
					</a-form-item>
				</a-form>
			</div>
		</div>

		<div class="mt20">
			<div class=" " style="min-height: 500px;">
				<div class="wxb-table-white">
					<a-table rowKey="id" :columns="columns" :pagination="pagination" @change="handleTableChange" :data-source="datas"
					 :loading="loading">
						<!-- <div  slot="member" slot-scope="member,record">
							<div v-if="member != null" class="flex alcenter center">
								<img v-if="member.face!=null" :src="member.face" class="member-money-recharge-face" />
								<img v-else src="../../assets/image/woyaoyou_head_defult@2x.png" class="member-money-recharge-face" />
								<div class="ml5" style="text-align: left;">
									<div>{{member.nick_name}}</div>
									<div class="mt2">{{member.mobile}}</div>
								</div>
							</div>
							<div v-else>-</div>
						</div> -->
						<div class="flex alcenter center" slot="id" slot-scope="record">
							{{record != null ? record.id : '-'}}
						</div>
						<div class="flex alcenter center" slot="mobile" slot-scope="record">
							{{record.member != null ? record.member.mobile : '-'}}
						</div>
						<div class="flex alcenter center" slot="mendian_name" slot-scope="record">
							{{record.mendian != null ? record.mendian.name : '-'}}
						</div>
						
						<div class="text-center" slot="original_deduction" slot-scope="original_deduction,record">
							¥{{original_deduction}}
						</div>
										 
						<div class="text-center" slot="money" slot-scope="record">
							{{record.type==1 ? '+' : '-'}} ¥{{record.balance}}
						</div>
						
						<!-- <div class="text-center" slot="give_balance" slot-scope="give_balance,record">
							+¥{{give_balance}}
						</div>
						
						<div class="text-center" slot="remarks" slot-scope="remarks,record">
							{{remarks ? remarks : '-'}}
						</div> -->
						<div class="text-center" slot="mendian" slot-scope="mendian,record">
							{{mendian ? mendian.name : '-'}}
						</div>
						<div class="text-center" slot="operator" slot-scope="record">
							<div v-if="record.operate_name && record.operate_mobile">
								<div class="ft14 cl-info">{{record.operate_name}}</div>
								<div class="ft12 cl-notice">({{record.operate_mobile}})</div>
							</div>
							<div v-else>-</div>
						</div>
					</a-table>
				</div>
			</div>
			
			<!-- <a-layout>
				<a-layout-header class="layout-classes-header">
					<a-menu class="classes-menu" :defaultSelectedKeys="[selectCurrent]" @click="selectMenu" mode="horizontal">
						<a-menu-item key="recharge">充值记录</a-menu-item>
						<a-menu-item key="consume">消耗记录</a-menu-item>
					</a-menu>
				</a-layout-header>
				<a-layout-content class="layout-classes-content">
					<div v-if="selectCurrent == 'recharge'">
						<money-recharge :search="search" ref="mychild"></money-recharge>
					</div>
					<div v-if="selectCurrent == 'consume'">
						<money-consume :search="search" ref="mychild"></money-consume>
					</div>
					
				</a-layout-content>
			</a-layout> -->
		</div>
	</div>
</template>

<script>
	import {
		listMixin
	} from '../../common/mixin/list.js';
	export default {
		mixins: [listMixin],
		data() {
			return {
				loading: false,
				search: {
					// nick_name: '',
					mobile: "",
					operate_time:['',''],
				},
				pagination: {
					current: 1,
					pageSize: 10, //每页中显示10条数据
					total: 0,
				},
				columns: [
					{title: '数据id',dataIndex: 'member_balance_logs_id',align: 'center'},
					{title: '手机号',key: 'mobile',align: 'center',scopedSlots: {customRender: 'mobile'}},
					{title: '所属门店名称',key: 'mendian_name',align: 'center',scopedSlots: {customRender: 'mendian_name'}},
					{ 	
						title: '类型',
						dataIndex: 'type',
						key: 'type',
						scopedSlots: { customRender: 'type' },
						customRender:(text,row,index)=>{
							return text==1?'增加':'减少'
						}
					},
					{title: '内容',dataIndex: 'info',align: 'center'},
					{title: '变动金额',dataIndex: 'balance',align: 'center'},
					{title: '赠送金额',dataIndex: 'give_balance',align: 'center'},
					{title: '创建时间',dataIndex: 'add_time_format',align: 'center'},
					{title: '备注',dataIndex: 'remarks',align: 'center'},
				],
				datas: [],
				
			}
		},
		methods: {
			
			getLists(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('admin/getMemberBalanceLogsList',{
					// nick_name:this.search.nick_name,
					mobile:this.search.mobile,
					operate_time:this.search.operate_time,
					limit:this.pagination.pageSize,
					page:this.pagination.current,
				}).then(res=>{
					this.pagination.total=res.total;
					this.datas=res.list;
					this.loading=false;
				}).catch(res=>{
					console.log(res);
					this.loading=false;
				})
			},
			
			handleTableChange(pagination, filters, sorter) {
				this.pagination.current = pagination.current;
				this.getLists();
			},
			
			changeOperateTime(value){
				this.search.operate_time = value;
			},
			
			searchCancel() {
				this.search = {
					nick_name: '',
					mobile: "",
					operate_time:['',''],
				};
			},
			searchAct(){
				this.getLists();
			},
			cancelAct(){
				this.searchCancel();
				this.searchAct();
			}
		}
	}
</script>

<style>
	.layout-classes-header {
		background: #FFFFFF;
	}
	
	.layout-classes-header {
		padding: 0 20px;
		height: 58px;
		line-height: 57px;
		border-bottom: 1px solid #F0F3F5;
		width: 100%;
	}
	
	.layout-classes-content {
		padding: 0px 0px 10px 0px;
		background: #FFFFFF;
		width: 100%;
		min-height: 700px;
	}
	
	.classes-menu {
		border-bottom: none;
		line-height: 55px;
		font-size: 14px;
	}
	
	.member-money-recharge-face {
		width: 44px;
		height: 44px;
		border-radius: 44px;
		box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.04);
		border: 2px solid #FFFFFF;
	}
</style>
